import React from 'react';
import {FaAddressCard, FaGithub, FaLinkedin} from "react-icons/fa";
import {IoMailUnread} from "react-icons/io5";
import {BsFillPersonLinesFill} from "react-icons/bs";
const Footer = () => {
    const links = [
        {
            id: 1,
            child: (
                <>
                    LinkedIn <FaLinkedin size={30}/>
                </>
            ),
            href: "https://www.linkedin.com/in/dimitri-aigle-914498221",
            style: 'rounded-tr-md'
        },
        {
            id: 2,
            child: (
                <>
                    GitHub <FaGithub size={30}/>
                </>
            ),
            href: "https://github.com/djimi971",
            style: 'rounded-tr-md'
        },
        {
            id: 3,
            child: (
                <>
                    Mail  <IoMailUnread size={30}/>
                </>
            ),
            href: "mailto:contact@dimitriaigle.fr",
            style: 'rounded-tr-md'
        },
        {
            id: 4,
            child: (
                <>
                    CV  <BsFillPersonLinesFill size={30}/>
                </>
            ),
            href: "/DimitriAIGLECV.pdf",
            style: 'rounded-tr-md',
            download: true,
        },
    ]
    return (
        <div name={"footer"} className={"w-full h-160 pt-60 bg-gradient-to-b from-Secondary-Dark to-Primary-Dark text-black"}>
            <div className={"max-w-screen-lg  mx-auto flex flex-col justify-center items-center center w-full h-full"}>
                <div className={"w-full grid grid-cols-1 sm:grid-cols-4 gap-8 items-center justify-center text-center py-8 px-12 sm:px-0"}>
                {links.map(({id, child, href, style, download}) =>(
                    <div key={id} className={"shadow-md shadow-Secondary-color rounded-lg"}>
                        <a href={href} className="flex justify-center items-center w-full text-black" download={download} target={"_blank"} rel={"noreferrer"}>
                            {child}
                        </a>
                    </div>
                ))}
                </div>
                <a className={"text-4xl font-major text-center font-bold mt-20"}>Copyright © {new Date().getFullYear()} Dimitri AIGLE</a>
            </div>
        </div>
    );
};

export default Footer;