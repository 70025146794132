import NavBar from "./Components/NavBar";
import Home from "./Components/Home";
import SocialLinks from "./Components/SocialLinks";
import About from "./Components/About";
import Portfolio from "./Components/Portfolio";
import Experience from "./Components/Experience";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";

export default function App() {
  return (

      <div>
          <meta name={"title"} content={"Dimitri AIGLE"}/>
         <NavBar/>
          <Home/>
          <About/>
          <Portfolio/>
          <Experience/>
          <Contact/>
          <SocialLinks/>
          <Footer/>
      </div>
  );
}
