import React from 'react';
import aa from '../assets/aa.jpg';
import {MdKeyboardArrowRight} from "react-icons/md";
import  {Link} from "react-scroll";


const Home = () => {
    return(
        <div name={"home"} className={"w-full h-screen  bg-gradient-to-b from-Primary-Dark via-Primary-Dark to-Secondary-Dark pt-36 "}>
            <div className={"max-w-screen-lg mx-auto flex flex-col md:flex-row items-center justify-center h-full px-4 "}>
                <div className={"flex flex-col justify-center h-full mt-40 sm:mt-0"}>
                    <h2 className={"text-6xl sm:text-7xl font-bold text-Primary-text "}>Je suis un développeur </h2>
                    <h2 className={"text-6xl sm:text-7xl font-bold text-Primary-text "}>Full Stack</h2>
                    <p className={"text-xl py-4 max-w-md font-major justify-between sm:justify-center font-bold "}>
                        Je suis en 3ème année de licence informatique MIAGE en contrat d'apprentissage jusqu'en septembre 2022.
                        J'aime apprendre, utiliser et travailler dans le milieu du software tels que Java,ReactJs,C,Android et bien d'autres.
                    </p>
                <div>
                <Link to={"portfolio"} smooth duration={500} className={"group text-lg font-bold text-black w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-Secondary-Light to-Secondary-color cursor-pointer"}>
                    Portfolio
                    <span className={"group-hover:rotate-90 duration-300"}>
                        <MdKeyboardArrowRight size={25} className={"ml-1"}/>
                    </span>
                </Link>
            </div>
                </div>
                    <div className={""}>
                        <img src={aa} alt="my profile" className={"rounded-2xl mx-auto w-2/3 md:w-full"}/>
                    </div>
            </div>
        </div>
    );
};

export default Home;