import React from 'react';

const About = () => {
    return (
        <div name={"about"} className={"w-full h-screen bg-gradient-to-b from-Secondary-Dark to-Primary-Dark text-black"}>
            <div className={"max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full"}>
                <div className={"pb-8 pt-3 mt-52 sm:mt-0"}>
                    <p className={"text-4xl font-major font-bold  inline border-b-4 border-black"}>
                        About
                    </p>
                </div>
            <p className={"text-2xl  font-bold mt-20"}>
                Passionné de musique et de chant je fais partie d'une chorale de jeune. Cette chorale est orchestrée
                par une association, qui nous permet de participer à des concerts et organise des rencontres.
                Ces mises en situation m’ont permis d'acquérir une certaine aisance face au public et de développer le savoir vivre en société.
            </p>
                <br/>
            <br/>
                <p className={"text-2xl font-bold justify-between"}>
                    Amoureux des jeux vidéo, je suis un joueur compétitif qui recherche toujours ses points faibles
                    dans le but de s’améliorer, avoir plus d'expérience. Je suis un joueur ardent
                    dans le développement de mes compétences. Je persévère afin d'atteindre mes objectifs.
            </p>
        </div>
        </div>
    );
};

export default About;