import React from 'react';

const Contact = () => {
    return (
        <div name={"contact"} className={"w-full h-screen  bg-gradient-to-b from-Primary-Dark to-Secondary-Dark p-4"}>
            <div className={"flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full"}>
                <div className={"pt-20 mt-96 sm:mt-0"}>
                    <p className={"text-5xl font-major inline border-b-4"}>Contact</p>
                    <p className={"py-6 text-3xl font-bold"}>Contactez moi via ce formulaire</p>
                </div>
                <div className={"flex justify-center items-center"}>
                <form action="https://getform.io/f/5ed902da-d5c4-4b5c-9f98-80845c7ea0e2" method={"POST"} className={"flex flex-col w-full md:w-1/2"}>
                    <input type="text" name={"name"}
                           placeholder={"Entrer votre nom"}
                           className={"p-2 my-4 bg-transparent border-2 rounded-md text-black focus:outline-none"}/>
                    <input type="text" name={"email"}
                           placeholder={"Entrer votre Email"}
                           className={"p-2 my-4 bg-transparent border-2 rounded-md text-black focus:outline-none"}/>
                    <textarea name={"name"} rows={"10"}
                           placeholder={"Entrer votre message"}
                              className={"p-2 bg-transparent border-2 rounded-md text-black focus:outline-none"}></textarea>
                    <button className={"text-black text-lg font-bold bg-gradient-to-b from-Secondary-Light to-Secondary-color px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300"}>Contactez moi</button>
                </form>
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default Contact;