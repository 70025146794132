import React from 'react';
import {FaLinkedin, FaGithub} from 'react-icons/fa'
import {IoMailUnread} from 'react-icons/io5'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import {FaAddressCard} from 'react-icons/fa'

const SocialLinks = () => {
    const links = [
        {
            id: 1,
            child: (
                <>
                    LinkedIn <FaLinkedin size={30}/>
                </>
            ),
            href: "https://www.linkedin.com/in/dimitri-aigle-914498221",
            style: 'rounded-tr-md'
        },
        {
            id: 2,
            child: (
                <>
                    GitHub <FaGithub size={30}/>
                </>
            ),
            href: "https://github.com/djimi971",
            style: 'rounded-tr-md'
        },
        {
            id: 3,
            child: (
                <>
                    Mail  <IoMailUnread size={30}/>
                </>
            ),
            href: "mailto:contact@dimitriaigle.fr",
            style: 'rounded-tr-md'
        },
        {
            id: 4,
            child: (
                <>
                    CV  <BsFillPersonLinesFill size={30}/>
                </>
            ),
            href: "/DimitriAIGLECV.pdf",
            style: 'rounded-tr-md',
            download: true,
        },/*
        {
            id: 5,
            child: (
                <>
                    Vcard  <FaAddressCard size={30}/>
                </>
            ),
            href: "",
            style: 'rounded-tr-md'
        }*/
    ]
    return (
        <div className={"hidden lg:flex flex-col top-[35%] left-0 fixed"}>
            <ul>
                {links.map(({id, child, href, style, download}) =>(

                <li key={id} className={"flex justify-between items-center w-40 h-14 px-4 ml-[-100px] " +
                    "hover:ml-[10px] hover:rounded-md duration-300 bg-Secondary-Dark"
                    + " " +style}>
                    <a href={href} className="flex justify-between items-center w-full text-Secondary-text" download={download} target={"_blank"} rel={"noreferrer"}>
                        {child}
                    </a>
                </li>
                ))}
            </ul>
        </div>
    );
};

export default SocialLinks;