import React from 'react';
import html from '../assets/html.png'
import css from '../assets/css.png'
import javascript from '../assets/javascript.png'
import github from '../assets/github.png'
import tailwind from '../assets/tailwind.png'
import reactImage from '../assets/react.png'
import phpmysql from '../assets/phpmysql.png'
import java from '../assets/java.png'
//import jetbrains from '../assets/jetbrains.png'
import androidstudio from '../assets/androidstudio.png'


const Experience = () => {
    const techs = [
        {
            id: 1,
            src: html,
            title: "HTMl",
            style: "shadow-orange-500",
        },
        {
            id: 2,
            src: css,
            title: "CSS",
            style: "shadow-blue-500",
        },
        {
            id: 3,
            src: javascript,
            title: "JS",
            style: "shadow-yellow-500",
        },
        {
            id: 4,
            src: reactImage,
            title: "React",
            style: "shadow-sky-400",
        },
        {
            id: 5,
            src: tailwind,
            title: "Tailwind",
            style: "shadow-sky-400"
        },
        {
            id: 6,
            src: phpmysql,
            title: "PhpMySQL",
            style: "shadow-yellow-500",
        },
        {
            id: 7,
            src: java,
            title: "Java",
            style: "shadow-red-600",
        },
        {
            id: 8,
            src: androidstudio,
            title: "Android Studio",
            style: "shadow-green-500",
        },
        {
            id: 9,
            src: github,
            title: "GitHub",
            style: "shadow-grey-400",
        },
    ]
    return (
        <div name={"experience"} className={"bg-gradient-to-b from-Secondary-Dark to-Primary-Dark w-full h-screen"}>
            <div className={"max-w-screen-lg p-4  mx-auto  flex flex-col justify-center w-full h-full "}>

                <div className={"mt-80  sm:mt-0"} >
                    <p className={"text-5xl font-bold font-major border-b-4 p-2 inline"}>Experience</p>
                    <p className={"py-6 text-3xl font-bold "}>Voici les technologies avec lesquelles j'ai déjà travaillé</p>
                </div>

            <div className={"w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0"}>

                {
                    techs.map(({ id, src, title, style}) => (

                        <div
                            key={id}
                            className={`shadow-lg hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                            <img src={src} alt="" className="w-20 mx-auto "/>
                            <p className={"mt-4 text-xl font-bold "}>{title}</p>
                        </div>

                    ))
                }

            </div>
         </div>
        </div>
    );
};

export default Experience;