import React, {useState} from 'react';
import {FaBars,FaTimes} from "react-icons/fa"
import {Link}  from "react-scroll"
const NavBar = () => {
    const [nav, setNav] = useState(false);
const links = [
    {
        id: 1,
        link: 'home'
    },
    {
        id: 2,
        link: 'about'
    },
    {
        id: 3,
        link: 'portfolio'
    },
    {
        id: 4 ,
        link: 'experience'
    },
    {
        id: 5,
        link: 'contact'
    },
];

    return (
        <div className={"flex justify-between items-center items-center w-full h-20 px-4 text-Primary-text bg-Primary-Dark fixed"}>
            <div>
                <h1 className={"text-4xl font-major ml-2 md:text-3xl sm:text-3xl"}>Dimitri AIGLE</h1>
            </div>
            <ul className="hidden md:flex">
                {links.map(({id, link}) =>(
                    <li key={id} className="px-4 cursor-pointer capitalize font-major text-2xl text-Primary-text hover:scale-200 duration-200 ">
                        <Link to={link} smooth duration={500}>{link}</Link>
                    </li>
                ))}
            </ul>
            <div onClick={()=>setNav(!nav)} className="cursor-pointer pr-4 z-10 text-Primary-text md:hidden">
                {nav ? <FaTimes size={30}/> : <FaBars size={30}/> }
            </div>
            {nav && (
                <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-Primary-Dark to-Secondary-Light text-Primary-text">
                    {links.map(({id, link}) =>(
                        <li
                            key={id}
                            className="px-4 cursor-pointer capitalize font-major py-6 text-4xl text-Primary-text hover:scale-200 duration-200">
                            <Link onClick={() => setNav(!nav)} to={link} smooth duration={500}>{link}</Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default NavBar;