import React from 'react';
import  calculatrice from '../assets/calculatrice.jpg'
import  calculatrice_android from '../assets/test_android2.jpg'
const Portfolio = () => {
    const portfolios =[
        {
            id: 1,
            src: calculatrice,
            demo: "https://calculatrice.dimitriaigle.fr/",
            code: "https://github.com/djimi971/calculatrice",
        },
        {
            id:2,
            src: calculatrice_android,
            demo: "",
            code: "https://github.com/djimi971/Calculatrice-Android",
        },
    ]
    return (
        <div name={"portfolio"}
             className={"bg-gradient-to-b from-Primary-Dark to-Secondary-Dark w-full text-black md:h-screen"}>
            <div className={"max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full"}>
                <div className={"pb-8 mt-40 sm:mt-0"}>
                    <p className={"text-4xl font-major font-bold  inline border-b-4 border-black"}>Porfolio</p>
                    <p className={"py-6 text-3xl font-bold"}>Jetez un coup d’œil à mes travaux ici</p>
                </div>
                <div  className={"grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0"}>
                {portfolios.map(({id,src,link,code}) =>(
                    <div key={id} className={"shadow-md shadow-Secondary-color rounded-lg"}>
                        <img src={src} alt="" className={"rounded-md duration-200 hover:scale-105"}/>
                        <div className={"flex items-center justify-center"}>
                            <a href={link} className={"w-1/2 px-6 py-3 m-4 duration-200 text-xl font-bold  hover:scale-200"}>Demo</a>
                            <a href={code} className={"w-1/2 px-6 py-3 m-4 duration-200 text-xl font-bold hover:scale-200"}>Code</a>
                        </div>
                </div>
                ))}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;